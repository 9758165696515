import { useState } from "react";

import { ChevronIcon } from "../Icons/ChevronIcon";

import opensea from "../../assets/logo/opensea.svg";

import objkt from "../../assets/logo/objkt.svg";

import magicEden from "../../assets/logo/magiceden.svg";

import { ArrowIcon } from "../Icons/ArrowIcon";

export const MarketplaceTab = () => {
  const [moreHovered, setMoreHovered] = useState<boolean>(false);

  return (
    <div
      onMouseOver={() => setMoreHovered(true)}
      onMouseLeave={() => setMoreHovered(false)}
      className="header-nav-button relative mr-10 flex cursor-pointer text-xxs text-white hover:text-pink"
    >
      <p className="font-bonnbf uppercase tracking-header">marketplace</p>

      {moreHovered ? (
        <ChevronIcon iconClass={"ml-2 fill-pink -rotate-90 w-2"}></ChevronIcon>
      ) : (
        <ChevronIcon iconClass={"ml-2 fill-pink rotate-90 w-2"}></ChevronIcon>
      )}

      {moreHovered && (
        <div className="absolute left-0 top-10 z-modal flex w-72 flex-col items-start justify-center gap-2 rounded-xl border border-white-10 bg-purple p-2 font-montserrat text-white">
          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://objkt.com/collection/dogami"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <img className="w-5" src={objkt} alt="opensea" />
              Objkt: Alpha Series
            </div>

            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>
          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://opensea.io/collection/dogami"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <img className="w-5" src={opensea} alt="opensea" />
              Opensea: Alpha Series
            </div>
            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>
          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://opensea.io/collection/dogami-gamma"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <img className="w-5" src={opensea} alt="opensea" />
              Opensea: Gamma Series
            </div>
            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>
          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://magiceden.io/collections/polygon/0xa3f2d95ff09ef87eb228d1aa965e06db4e9ce71b"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <img className="w-5" src={magicEden} alt="magicEden" />
              Magic Eden: Alpha Series
            </div>
            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>
          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://magiceden.io/collections/polygon/0xb953ACa746f3b4AB5C9E5A6aa9A6C986a8599Be5"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <img className="w-5" src={magicEden} alt="magicEden" />
              Magic Eden: Gamma Series
            </div>
            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>
          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://magiceden.io/collections/abstract/0x53b5633b6233008b117232664db57a525b71eac8"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <img className="w-5" src={magicEden} alt="magicEden" />
              Magic Eden: Gamma Abstract
            </div>
            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>
        </div>
      )}
    </div>
  );
};
